import React from "react";
import CollectionPageSection from "../../components/CollectionPageSection";
import NavbarLogo from "../../components/NavbarLogo";

const CollectionPage = () => {
  return (
    <>
      <NavbarLogo />
      <CollectionPageSection />
    </>
  );
};

export default CollectionPage;
