import React from "react";
import NavbarLogo from "../../components/NavbarLogo";
import VariationPageSection from "../../components/VariationPageSection";

const VariationPage = () => {
  return (
    <>
      <NavbarLogo />
      <VariationPageSection />
    </>
  );
};

export default VariationPage;
