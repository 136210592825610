import React from "react";
import PostPageSection from "../../components/PostPageSection";
import NavbarLogo from "../../components/NavbarLogo";

const PostPage = () => {
  return (
    <>
      <NavbarLogo />
      <PostPageSection />
    </>
  );
};

export default PostPage;
