import React from "react";
import NavbarLogo from "../../components/NavbarLogo";
import CatalogItemPageSection from "../../components/CatalogItemPageSection";

const CatalogItemPage = () => {
  return (
    <>
      <NavbarLogo />
      <CatalogItemPageSection />
    </>
  );
};

export default CatalogItemPage;
