import React, { useState } from "react";
import Modal from "../Modal";
import {
  CloseIcon,
  Icon,
  NavLinks2,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SideBtnWrap,
  SiderbarWrapper,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SiderbarWrapper>
        <SidebarMenu>
          <SidebarLink to="acervos" onClick={toggle}>
            Acervos
          </SidebarLink>
          <SidebarLink to="catalogo" onClick={toggle}>
            Catálogo
          </SidebarLink>
          <SidebarLink to="interacoes" onClick={toggle}>
            Interações
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <NavLinks2 to="download" onClick={toggle}>
            Baixe agora
          </NavLinks2>
        </SideBtnWrap>
      </SiderbarWrapper>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </SidebarContainer>
  );
};

export default Sidebar;
