import React, { useState } from "react";
import {
  HeroBtnWrapper,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP2,
  ImgBg,
} from "./HeroElements";

import { Button } from "../ButtonElement";

import Image1 from "../../images/Mobile_Acervo.png";
import { GlobalStyle } from "../globalStyle";
import Modal from "../Modal";

const HeroSection = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <HeroH1>A primeira rede social para colecionadores</HeroH1>
      <HeroContainer>
        <ImgBg src={Image1} />
      </HeroContainer>
      <HeroContent>
        <HeroP2>
          Crie e organize suas coleções, compartilhe informações sobre a
          aquisição de um item raríssimo e conheça outros colecionadores de
          acordo com o seu nicho de interesse!
        </HeroP2>
        <HeroBtnWrapper>
          <Button to="signup" onClick={openModal} primary="true" dark="true">
            Mais informações
          </Button>
          <GlobalStyle />
        </HeroBtnWrapper>
      </HeroContent>
    </>
  );
};

export default HeroSection;
