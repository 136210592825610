import React, { useState } from "react";
import LogoEscrito from "../../images/logo_escrito_v2.png";
import { FaBars } from "react-icons/fa";
import {
  Container,
  Logo,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavItem,
  NavLinks,
  NavLinks2,
  NavLogo,
  NavMenu,
} from "./NavbarElements";

import Modal from "../Modal";

const Navbar = ({ toggle }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <Logo src={LogoEscrito}></Logo>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars></FaBars>
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="acervos">Acervos</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="catalogo">Catálogo</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="interacoes">Interações</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavLinks2 to="download">Baixe agora</NavLinks2>
          </NavBtn>
        </NavbarContainer>
      </Nav>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default Navbar;
