import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #fff;
`;

export const FooterWrap = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 200px;
  max-width: 1100px;
  margin: 0 auto;
`;

export const NavLogo = styled.div`
  background-color: #f4c82e;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  height: 80px;
  width: 180px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-bottom: 15px;
`;

export const Logo = styled.img`
  width: 90%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const Subtitle = styled.p`
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-family: BaiJamjuree-Medium;
  color: #333333;
`;
