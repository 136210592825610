import React from "react";
import LogoEscrito from "../../images/logo_escrito_v2.png";
import {
  Container,
  Logo,
  Nav,
  NavbarContainer,
  NavLogo,
} from "./NavbarElements";

const NavbarLogo = () => {
  return (
    <Container>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <Logo src={LogoEscrito}></Logo>
          </NavLogo>
        </NavbarContainer>
      </Nav>
    </Container>
  );
};

export default NavbarLogo;
