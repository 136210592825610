import React from "react";
import { ImagesContainer, Img } from "./ImagesElements";

import Image2 from "../../images/section_download.png";

const ImageSection = (category) => {
  return (
    <ImagesContainer>
      <Img src={Image2} />
    </ImagesContainer>
  );
};

export default ImageSection;
