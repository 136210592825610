import React from "react";

import { Container } from "./InfoElements";
import { Message } from "./InfoElements";
import { Title } from "./InfoElements";
import { LoginButton } from "./InfoElements";
import { Text } from "./InfoElements";
import { Img } from "./InfoElements";

const VerifiedEmailSection = () => {
  return (
    <Container>
      <Message>
        <Title>Sua conta foi verificada com sucesso!</Title>
        <Text>
          Faça seu login no aplicativo para criar seus acervos e conhecer outros
          colecionadores
        </Text>
        <LoginButton href="/login">Fazer login no app</LoginButton>
        <Img src={require("../../images/logo_oficial.png")}></Img>
      </Message>
    </Container>
  );
};

export default VerifiedEmailSection;
