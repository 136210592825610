import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
  Container,
  InputProps,
  Message,
  SendButton,
  Text,
  ContainerText,
} from "./InfoElements";
import api from "../../services/api";

const ForgotPasswordSection = ({ token }) => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordStrength, setPasswordStrength] = useState();
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [loading, setLoading] = useState(false);

  const scorePassword = (pass) => {
    let score = 0;
    if (!pass) return score;

    const letters = {};

    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;

    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }

    score += (variationCount - 1) * 10;

    return parseFloat(score);
  };

  function checkPassStrength(pass) {
    const score = scorePassword(pass);
    if (score > 80) {
      setPasswordStrength("Senha forte");
      setIsPasswordStrong(true);
    } else if (score >= 30) {
      setPasswordStrength("Senha média");
      setIsPasswordStrong(false);
    } else {
      setPasswordStrength("Senha fraca");
      setIsPasswordStrong(false);
    }
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkPassStrength(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await api.post("/password/confirm", {
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      });

      window.alert("Senha trocada com sucesso!");

      setLoading(false);

      window.location.href = "/";
    } catch (error) {
      setLoading(false);

      window.alert(
        "Ocorreu um erro ao trocar a senha. Por favor, tente novamente."
      );
    }
  };

  return (
    <Container>
      <Message>
        <ContainerText>
          <Text>
            Crie uma nova senha com pelo menos 8 caracteres, uma letra maiúscula
            e um caractere especial
          </Text>
        </ContainerText>
        <InputProps
          width="100%"
          border="4px"
          placeholder="Nova senha"
          autoCorrect={false}
          type="password"
          onChange={handlePasswordChange}
          value={password}
        />
        {passwordStrength === "Senha forte" ? (
          <ContainerText justify="flex-end" align="flex-end">
            <Text>{passwordStrength}</Text>
          </ContainerText>
        ) : null}
        <InputProps
          width="100%"
          border="4px"
          placeholder="Confirmar nova senha"
          autoCorrect={false}
          type="password"
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          disabled={!isPasswordStrong}
        />
        <SendButton
          onClick={handleConfirm}
          disabled={
            !password ||
            !confirmPassword ||
            password !== confirmPassword ||
            loading
          }
        >
          {loading ? (
            <ThreeDots
              height="10"
              width="80"
              radius="9"
              color="#333"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Trocar senha"
          )}
        </SendButton>
      </Message>
    </Container>
  );
};

export default ForgotPasswordSection;
