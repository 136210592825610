import React from "react";
import NavbarLogo from "../../components/NavbarLogo";
import ItemPageSection from "../../components/ItemPageSection";

const ItemPage = () => {
  return (
    <>
      <NavbarLogo />
      <ItemPageSection />
    </>
  );
};

export default ItemPage;
