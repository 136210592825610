import React from "react";
import ForgotPasswordSection from "../../components/ForgotPasswordSection";
import NavbarLogo from "../../components/NavbarLogo";
import { useSearchParams } from "react-router-dom";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  return (
    <>
      <NavbarLogo />
      <ForgotPasswordSection token={token} />;
    </>
  );
};

export default ForgotPassword;
