export const objDownload = {
  id: "download",
  lightBg: true,
  lightText: false,
  headline: "Baixe agora mesmo",
  subtitle: "Acervo • Todos os direitos reservados",
  imgStart: false,
  img1: require("../../images/appstore.png"),
  img2: require("../../images/googleplay.png"),
  alt1: "Baixar na App Store",
  alt2: "Baixar no Google Play",
};
