import React from "react";
import { FooterContainer, Subtitle } from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <Subtitle>acervo.app.br • Todos os direitos reservados</Subtitle>
    </FooterContainer>
  );
};

export default Footer;
