import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  background-color: #fff;
  padding: 10px;
`;

export const Message = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const AppButton = styled.a`
  display: inline-block;
  border: 2px solid #333;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #f4c82e;
  color: #000;
  text-decoration: none;
  font-family: BaiJamjuree-SemiBold;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 25%;
  margin: 0 0 10px 0;

  @media screen and (max-width: 480px) {
    width: 25%;
  }
`;
