import React from "react";

import {
  Heading,
  Img,
  ImgWrap,
  InfoContainer,
  InfoWrapper,
} from "./InfoElements";

const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "unknown";
};

const DownloadSection = ({
  lightBg,
  id,
  lightText,
  headline,
  img1,
  alt1,
  img2,
  alt2,
}) => {
  const os = getMobileOS();

  console.log(os);

  return (
    <InfoContainer lightBg={lightBg} id={id}>
      <Heading lightText={lightText}>{headline}</Heading>
      <InfoWrapper>
        {(os === "iOS" || os === "unknown") && (
          <ImgWrap>
            <Img src={img1} alt={alt1}></Img>
          </ImgWrap>
        )}
        {(os === "Android" || os === "unknown") && (
          <ImgWrap>
            <Img src={img2} alt={alt2}></Img>
          </ImgWrap>
        )}
      </InfoWrapper>
    </InfoContainer>
  );
};

export default DownloadSection;
