import styled from "styled-components";

export const ImagesContainer = styled.nav`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding-top: 40px;
  padding-bottom: 10px;
  max-width: 100%;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 350px;
  }
`;

export const Img = styled.img`
  width: 550px;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 414px;
    height: 250px;
  }

  @media screen and (max-width: 480px) {
    width: 350px;
    height: 200px;
  }
`;
