import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const Container = styled.nav`
  background: #ffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //position: relative;
  max-width: 100%;
  z-index: 1;
`;

export const HeroContainer = styled.nav`
  background: #ffff;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
  height: 400px;
  //position: relative;
  max-width: 100%;
  z-index: 1;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 340px;
  }
`;

export const ImgBg = styled.img`
  width: 200px;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 190px;
  }

  @media screen and (max-width: 480px) {
    width: 180px;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  width: 100%;
  //max-width: 1200px;
  //position: relative;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #333333;
  font-size: 40px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroPContent = styled.div`
  max-width: 600px;
  //background-color: #f4c82e;
  justify-content: center;
  align-items: center;
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #333333;
  background-color: #f4c82e;
  font-size: 24px;
  font-family: BaiJamjuree-SemiBold;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const HeroP2 = styled.p`
  margin-top: 24px;
  color: #333333;
  font-size: 22px;
  font-family: BaiJamjuree-Medium;
  text-align: center;
  max-width: 1000px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
