export const homeObjOne = {
  id: "acervos",
  lightBg: false,
  lightText: true,
  lighTextDesc: false,
  topLine: "Acervos",
  headline: "Compartilhe seus acervos",
  description:
    "Crie suas coleções de acordo com seu nicho! É o lugar ideal para divulgar sua coleção de chuteira, de camisas de time, de cachecóis, de medalhas...",
  buttonLabel: "Começar agora",
  imgStart: false,
  img: require("../../images/section_acervos.png"),
  alt: "Exposicao",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "catalogo",
  lightBg: true,
  lightText: false,
  lighTextDesc: false,
  topLine: "Catálogo",
  headline: "Organização em primeiro lugar",
  description:
    "Uma opção para a sua planilha! Crie wishlists, sinalize os itens que você já tem e organize sua coleção com filtros personalizáveis: ano, clube, tipo de camisa, atleta e outros.",
  buttonLabel: "Começar agora",
  imgStart: true,
  img: require("../../images/section_catalogo.png"),
  alt: "Exposicao",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjThree = {
  id: "interacoes",
  lightBg: false,
  lightText: true,
  lighTextDesc: false,
  topLine: "Interações",
  headline: "Compartilhe e interaja",
  description:
    "Agora você conta com uma comunidade para compartilhar suas aquisições com quem entende e se interessa pelo assunto. Aproveite para curtir, comentar e interagir com colecionadores de todo o país.",
  buttonLabel: "Começar agora",
  imgStart: false,
  img: require("../../images/section_interacoes.png"),
  alt: "Exposicao",
  dark: true,
  primary: true,
  darkText: false,
};
