import React, { useState } from "react";
import Footer from "../../components/Footer";
import HeroSection from "../../components/HeroSection";
import ImageSection from "../../components/ImageSection";
import InfoSection from "../../components/InfoSection";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
} from "../../components/InfoSection/Data";

import { objDownload } from "../../components/DownloadSection/Data";
import DownloadSection from "../../components/DownloadSection";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      <ImageSection />
      <DownloadSection {...objDownload} />
      <Footer />
    </>
  );
};

export default Home;
