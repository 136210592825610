import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerText = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "center"};
  margin-bottom: 10px;
  margin-top: 10px;

  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;

export const Message = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: BaiJamjuree-Medium;
`;

export const SendButton = styled.a`
  display: inline-flex;
  border: 2px solid #333;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #f4c82e;
  color: #000;
  text-decoration: none;
  font-family: BaiJamjuree-SemiBold;
  height: 45px;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const Img = styled.img`
  width: 30%;
  margin: 0 0 10px 0;

  @media screen and (max-width: 480px) {
    width: 25%;
  }
`;

export const InputProps = styled.input`
  padding: 15px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
`;
