import React from "react";

import {
  Background,
  CloseModalButton,
  ModalContent,
  ModalWrapper,
} from "./ModalElements";

const Modal = ({ showModal, setShowModal }) => {
  return (
    <>
      {showModal ? (
        <Background>
          <ModalWrapper showModal={showModal}>
            <ModalContent>
              <h1>Baixe o aplicativo agora mesmo</h1>
              <p>
                Ou entre em contato conosco no e-mail contato@acervo.app.br para
                mais detalhes!
              </p>
            </ModalContent>
            <CloseModalButton
              aria-label="Fechar janela"
              onClick={() => setShowModal((prev) => !prev)}
            />
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};

export default Modal;
