import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const Container = styled.nav`
  padding: 5px;
`;

export const Nav = styled.nav`
  background: #f4c82e;
  border-radius: 8px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  padding: 0 20px;
  max-width: 100%;
`;

export const NavLogo = styled(LinkR)`
  cursor: pointer;
  display: flex;
  height: 70px;
  width: 180px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;
