import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import VerifiedEmail from "./pages/VerifiedEmail";
import ForgotPassword from "./pages/ForgotPassword";
import CollectionPage from "./pages/CollectionPage";
import PostPage from "./pages/PostPage";
import ItemPage from "./pages/ItemPage";
import VariationPage from "./pages/VariationPage";
import CatalogItemPage from "./pages/CatalogoItemPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/verify-email" element={<VerifiedEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/:name_id" element={<CollectionPage />} />
        <Route path="/:name_id/post/:id" element={<PostPage />} />
        <Route path="/:name_id/item/:id" element={<ItemPage />} />
        <Route path="/:name_id/variation/:id" element={<VariationPage />} />
        <Route path="/explore/catalog/:id" element={<CatalogItemPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
