import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#333333")};
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  height: 250px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  text-align: center;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#333333")};

  @media screen and (max-width: 480px) {
    font-size: 36px;
  }
`;

export const ImgWrap = styled.div`
  width: 200px;
  margin-left: 50px;
  margin-right: 50px;

  @media screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
